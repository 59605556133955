import { useCheckToggle } from "@/common/use-check-toggle";
import { useUnleashToggles } from "@/common/use-unleash-toggles";
import { setEventTracking } from "@/utils";
import { useFlag } from "@flash-tecnologia/feature-flags";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  getAccessTokenPayloadSync,
  useLegacyPermissions,
  useViewMode,
  VIEW_MODE_ENUM,
} from "@flash-tecnologia/hros-web-utility";
import { MenuItem } from "@mui/material";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ActionListType, useActionsList } from "../hooks/useActionsList";
import { useSession } from "../hooks/useSession";
import { ICompanyWithPermissions, ISelectedCompany } from "../types";
import ActionItem from "./ActionItem";
import { CompaniesList } from "./CompaniesList";
import { CompaniesList as CompaniesListV2 } from "./CompaniesListV2";
import { HeaderItem } from "./HeaderItem";
import {
  Divider,
  SelectCompanyMenuIcon,
  SelectedCompanyContainer,
  SelectedCompanyMenu,
  SelectedCompanyTitle,
} from "./styled";
import { UnifiedVisionToggle } from "./UnifiedVisionToggle";
interface SelectCompanyMenuProps {
  companies: ICompanyWithPermissions[];
  selectedCompany?: ISelectedCompany;
  onChange: (company: ISelectedCompany) => void;
}

// TODO - Move this to a centralized location
const unifiedVisionEnabledRoutes = ["/home/admin", "/employees"];

const SelectCompanyMenu = ({
  companies,
  selectedCompany,
  onChange,
}: SelectCompanyMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { economicGroupId } = getAccessTokenPayloadSync();
  const unleashToggles = useUnleashToggles({
    economicGroupId: economicGroupId,
  });
  const { checkUnleashToggle } = useCheckToggle();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { permissions } = useSession();
  const { mode } = useViewMode();
  const { checkPathPermission } = useLegacyPermissions();
  const isCompanyCreationEnabled = !useFlag({
    flagName: "FLASH_OS_FORCE_LEGACY_COMPANY_CREATION_UX",
  });
  const actionsList = useActionsList();

  const isUnifiedVision = useMemo(
    () => mode === VIEW_MODE_ENUM.ECONOMIC_GROUP,
    [mode]
  );

  const selectedCompanyPermissions = useMemo(() => {
    const { permissions: companyPermissions } =
      permissions.companies.find(
        (company) => company.id === selectedCompany?.id
      ) || {};
    return companyPermissions;
  }, [selectedCompany, permissions]);

  const companiesItems = useMemo(() => {
    return companies.map(({ id, name, registrationNumber }) => ({
      onClick: () => handleCompanySelection({ id, name, registrationNumber }),
      company: { id, name, registrationNumber },
    }));
  }, [companies]);

  const checkActionPermissions = (action: ActionListType) => {
    switch (action.key) {
      case "add-company":
        return canAddCompany();
      case "legacy-add-company":
        return canLegacyAddCompany(action.path);
      case "manage-companies":
        return canManageCompany();
      case "manage-admin":
        return canManageAdmin();
      case "manage-superadmin":
        return isSuperAdmin();
    }
  };

  const canManageAdmin = () => {
    return (
      selectedCompanyPermissions?.includes("core_manage_permissions") ||
      permissions?.isAdmin
    );
  };

  const canLegacyAddCompany = (actionPath: string) => {
    return checkPathPermission(actionPath) && !isCompanyCreationEnabled;
  };

  const canAddCompany = () => {
    return permissions?.isAdmin && isCompanyCreationEnabled;
  };

  const isSuperAdmin = () => {
    return permissions?.isAdmin;
  };

  const canManageCompany = () => {
    return (
      selectedCompanyPermissions?.includes("core_manage_company_settings") ||
      permissions?.isAdmin
    );
  };

  const closeMenu = () => setAnchorEl(null);

  const handleCompanySelection = async (company: ISelectedCompany) => {
    closeMenu();
    onChange(company);
  };

  const handleMenuButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    closeMenu();
  };

  const handleEventTracking = (path: string) => {
    const pathEventMap: { [key: string]: string } = {
      "/settings/permissions/administrators/view":
        "header_options_manage_super_admins_clicked",
      "/settings/permissions/administrators":
        "header_options_manage_super_admins_clicked",
      "/settings/permissions/view": "header_options_manage_admins_clicked",
      "/settings/permissions": "header_options_manage_admins_clicked",
    };
    const eventName = pathEventMap[path];
    if (eventName) {
      setEventTracking(eventName, { path });
    }
  };

  const handleActionClick = (
    path: string,
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    closeMenu();
    handleEventTracking(path);
    navigate(path);
  };

  const filteredActions = useMemo(() => {
    return actionsList
      .filter((action) => checkActionPermissions(action))
      .map(({ key, label, icon, path }) => (
        <MenuItem
          key={`action-${key}`}
          onClick={(e) => handleActionClick(path, e)}
        >
          <ActionItem icon={icon} label={label} />
        </MenuItem>
      ));
  }, [permissions, selectedCompany, isCompanyCreationEnabled, actionsList]);

  const enabledCompaniesListV2 = checkUnleashToggle(
    "FLASH_OS_ENABLE_UNIFIED_VISION",
    unleashToggles
  );

  const isEnabledUnifiedVision = useMemo(
    () => unifiedVisionEnabledRoutes.includes(pathname),
    [pathname]
  );

  const canUseUnifiedVision = useMemo(
    () =>
      permissions.isAdmin && enabledCompaniesListV2 && isEnabledUnifiedVision,
    [permissions, enabledCompaniesListV2, isEnabledUnifiedVision]
  );

  const listTitle = useMemo(
    () => (isUnifiedVision ? "Grupo Econômico" : selectedCompany.name),
    [mode, selectedCompany]
  );

  return (
    <div>
      <button onClick={handleMenuButtonClick}>
        <SelectedCompanyContainer>
          <Icons
            name="IconBuildingCommunity"
            size={16}
            color="var(--color-neutral-50)"
            style={{ flexShrink: 0 }}
          />
          <SelectedCompanyTitle
            variant="caption"
            variantColor="var(--color-neutral-50)"
            children={listTitle}
          />
          {companies.length > 0 ? (
            <SelectCompanyMenuIcon
              name="IconCaretDownFilled"
              size={16}
              color="var(--color-neutral-50)"
              style={{ flexShrink: 0 }}
            />
          ) : null}
        </SelectedCompanyContainer>
      </button>

      <SelectedCompanyMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {canUseUnifiedVision ? (
          <div>
            <UnifiedVisionToggle />
            <CompaniesListV2 companies={companiesItems} />
          </div>
        ) : (
          <div>
            <HeaderItem title={companies[0].name} />
            <Divider />
            <CompaniesList companies={companiesItems} />
            <Divider />
          </div>
        )}

        {filteredActions}
      </SelectedCompanyMenu>
    </div>
  );
};

export default SelectCompanyMenu;
