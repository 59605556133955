import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { LinearProgress } from "@mui/material";

const OnboardingStepsMenu = styled.button`
  border-radius: ${({ theme }) => theme.borders.radius.s};
  background: ${({ theme }) => theme.colors.secondary[99]};
  overflow: hidden;

  &:hover {
    opacity: 0.5;
  }
`;

const OnboardingStepsContainer = styled.div`
  height: 30px;
  max-width: 540px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
  padding: 8px 12px;
  cursor: pointer;
  text-align: center;
`;

const OnboardingStepTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
  font-weight: 600;
  max-width: 320px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  text-align: left;
`;

const ProgressBar = styled(LinearProgress)`
  height: 2px;
  flex-shrink: 0;
`;

export {
  OnboardingStepsMenu,
  OnboardingStepsContainer,
  OnboardingStepTitle,
  ProgressBar,
};
