import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { Loader } from "@flash-tecnologia/hros-web-ui-v2";
import { useFlag } from "@flash-tecnologia/feature-flags";
import { useTranslation } from "react-i18next";
import { BodyContainer, LoaderContainer, MainContainer } from "./styled";
import { NotificationFeedProps } from "./types";
import {
  Header,
  Tabs,
  EmptyMessage,
  Skeleton,
  NotificationList,
} from "./components";
import IconEmpty from "../../assets/IconEmpty.svg";
import SectionLoading from "../SectionLoading";
import { Context } from "@/context";
import { HeaderNotification } from "@flash-hros/home";
import { useTheme } from "styled-components";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

export default ({
  visible,
  setVisible,
  notifications,
  onScroll,
  onClose,
}: NotificationFeedProps) => {
  const { selectedCompany } = useSelectedCompany();
  const [t] = useTranslation("translations", {
    keyPrefix: "notification",
  });
  const inboxEnabled = useFlag({ flagName: "FLASH_OS_INBOX" });
  const location = useLocation();

  const theme = useTheme();

  const { loading } = useContext(Context);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const $feed = document?.getElementById("notification-feed");

  useEffect(() => {
    if (notifications?.length && scrollTop > 0 && onScroll) onScroll();
  }, [scrollTop]);

  useEffect(() => {
    setVisible(false);
  }, [location]);

  const handleScroll = () => {
    if (onScroll && !loading?.urls?.includes("getEmployeeNotifications")) {
      const currentScrollTop = $feed.scrollTop;
      if (currentScrollTop === 0) return;
      if (currentScrollTop > scrollTop + 300) setScrollTop(currentScrollTop);
    }
  };

  const onCloseFeed = () => {
    if (visible && onClose) onClose();
    setVisible(!visible);
  };

  const renderNotifications = useCallback(() => {
    return (
      <NotificationList notifications={notifications} onSubmit={onCloseFeed} />
    );
  }, [notifications?.length]);

  return (
    <MainContainer
      id="notification-feed"
      visible={visible}
      onScroll={handleScroll}
    >
      <Header title={t("title")} onClose={onCloseFeed} />
      {inboxEnabled ? (
        <BodyContainer>
          <HeaderNotification
            key={selectedCompany.id}
            bgColor={theme.colors.neutral[100]}
            inboxOptions={{
              invertTitle: false,
              invertButton: true,
              uniqueList: false,
              showAckAll: true,
            }}
            notificationsOptions={{
              showNotificationDetails: true,
            }}
          />
        </BodyContainer>
      ) : (
        <Tabs
          tabItens={[
            {
              label: t("firstTab"),
              component: (
                <>
                  <SectionLoading url="getEmployeeNotifications">
                    {({ loading }) =>
                      loading ? (
                        notifications?.length ? (
                          <>
                            {renderNotifications()}
                            <LoaderContainer>
                              <Loader variant="primary" size="small" />
                            </LoaderContainer>
                          </>
                        ) : (
                          <Skeleton />
                        )
                      ) : !notifications?.length ? (
                        <EmptyMessage
                          headerElement={<IconEmpty />}
                          title={t("emptyMessageTitle")}
                          message={t("emptyMessageText")}
                        />
                      ) : (
                        renderNotifications()
                      )
                    }
                  </SectionLoading>
                </>
              ),
            },
          ]}
        />
      )}
    </MainContainer>
  );
};
