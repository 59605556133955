import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledUnifiedVisionContainer = styled.div<{ active?: boolean }>`
  border: solid 1px
    ${({ theme, active }) => (active ? "#FFF1F8" : theme.colors.neutral[90])};
  border-radius: 8px;
  background-color: ${({ theme, active }) =>
    active ? "#FFF1F8" : theme.colors.neutral[100]};
`;

export const StyledUnifiedVisionHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px;
`;

export const StyledUnifiedVisionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledUnifiedVisionDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledUnifiedVisionFooter = styled.div<{ active?: boolean }>`
  padding: 6px 8px;
  border-top: solid 1px
    ${({ theme, active }) => (active ? "#FFF1F8" : theme.colors.neutral[90])};
  background-color: ${({ theme, active }) =>
    active ? "#FFE7F2" : theme.colors.neutral[100]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
`;

export const StyledUnifiedVisionFooterTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledUnifiedVisionFooterTitleGroup = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledTagContainer = styled.div`
  span {
    background-color: ${({ theme }) => theme.colors.secondary[90]};
  }

  svg {
    background-color: transparent;
  }
`;
