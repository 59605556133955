import { TextField, Typography, Radio } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledCompanyItem = styled.div<{
  active?: boolean;
  activePath?: boolean;
  activeHorizontalPath?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 8px;
  position: relative;
  border: ${({ theme, active }) =>
    active
      ? `solid 1px ${theme.colors.secondary[95]}`
      : `solid 1px ${theme.colors.neutral[90]}`};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.secondary[95] : theme.colors.neutral[100]};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? ".7" : "1")};

  &:before {
    content: "";
    display: block;
    width: 15px;
    height: 1px;
    position: absolute;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 0.5px dashed
      ${({ theme, activeHorizontalPath }) =>
        activeHorizontalPath
          ? theme.colors.secondary[80]
          : theme.colors.neutral[90]};
  }

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: calc(50% + 40px);
    position: absolute;
    left: -16px;
    top: -40px;
    border-left: 0.5px dashed
      ${({ theme, activePath }) =>
        activePath ? theme.colors.secondary[80] : theme.colors.neutral[90]};
  }

  &:first-child:after {
    top: -40px;
  }

  &:last-child:after {
    height: calc(100% + 8px);
  }
`;

export const StyledCompanyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCompanyItemTitle = styled(Typography)<{ active?: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.colors.secondary[50] : theme.colors.neutral[30]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
`;

export const StyledCompanyItemDescription = styled(Typography)<{
  active?: boolean;
}>`
  color: ${({ theme, active }) =>
    active ? theme.colors.secondary[50] : theme.colors.neutral[40]};
`;

export const StyledList = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 30px;
  margin-top: 10px;
  padding-right: 10px;
`;

export const StyledSearchInputContainer = styled.div<{ active?: boolean }>`
  margin: 12px 10px 6px 30px;
  padding: 6px 10px;
  position: relative;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.pill};
  display: flex;
  align-items: center;
  gap: 2px;

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: calc(100% + 22px);
    position: absolute;
    left: -16px;
    top: -12px;
    border-left: 0.5px dashed
      ${({ theme, active }) =>
        active ? theme.colors.secondary[80] : theme.colors.neutral[90]};
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  color: ${(props) => props.theme.colors.neutral[30]};
  font-weight: 600;

  & .MuiInputBase-root {
    border: none !important;
  }

  & .MuiInputBase-input {
    padding: 0px 10px !important;
    font-size: 14px;
  }
`;

export const StyledRadio = styled(Radio)`
  transform: scale(0.7) translateX(10px);
`;

export const StyledTreeContainer = styled.div`
  margin-left: 30px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 10px;
`;

export const StyledListContainer = styled.div`
  overflow-y: auto;
  max-height: 250px;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

export const StyledEmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 16px 20px;
`;

export const StyledEmptyStateIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledEmptyStateTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  text-align: center;
`;

export const StyledEmptyStateDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  text-align: center;
`;
