import { useCheckToggle } from "@/common/use-check-toggle";
import { useUnleashToggles } from "@/common/use-unleash-toggles";
import { getAccessTokenPayloadSync } from "@flash-tecnologia/hros-web-utility";
import { useMemo } from "react";

export type ActionListKeys =
  | "add-company"
  | "legacy-add-company"
  | "manage-admin"
  | "manage-superadmin"
  | "manage-companies";

export type ActionListType = {
  key: ActionListKeys;
  label: string;
  icon: string;
  path: string;
};

export const useActionsList = () => {
  const { economicGroupId } = getAccessTokenPayloadSync();
  const { checkUnleashToggle } = useCheckToggle();
  const unleashToggles = useUnleashToggles({
    economicGroupId: economicGroupId,
  });
  const permissionsViewEnabled = checkUnleashToggle(
    "FLASH_OS_PERMISSIONS_VIEW",
    unleashToggles
  );

  const actionsList: ActionListType[] = useMemo(() => {
    const manageAdminPath = permissionsViewEnabled
      ? "/settings/permissions/view"
      : "/settings/permissions";
    const manageSuperAdminPath = permissionsViewEnabled
      ? "/settings/permissions/administrators/view"
      : "/settings/permissions/administrators";
    return [
      {
        key: "add-company",
        label: "Adicionar empresa",
        icon: "IconPlus",
        path: "/settings/add-company",
      },
      {
        key: "legacy-add-company",
        label: "Cadastrar empresa",
        icon: "IconCirclePlus",
        path: "/benefits/dashboard/company/register",
      },
      {
        key: "manage-companies",
        label: "Gerenciar empresas",
        icon: "IconSettings",
        path: "/settings/",
      },
      {
        key: "manage-admin",
        label: "Gerenciar administradores",
        icon: "IconKey",
        path: manageAdminPath,
      },
      {
        key: "manage-superadmin",
        label: "Gerenciar super administradores",
        icon: "IconShieldCheck",
        path: manageSuperAdminPath,
      },
    ];
  }, [permissionsViewEnabled]);

  return actionsList;
};
