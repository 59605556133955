import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacings.xs3} ${theme.spacings.xs}`};
`;

const StyledRightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

const StyledLeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

const StyledInfoTag = styled.div`
  padding: 4px 12px;
  border-radius: ${({ theme }) => theme.borders.radius.pill};
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #d4e8ff;
  color: #015fcb;
`;

export {
  StyledContainer,
  StyledRightContainer,
  StyledLeftContainer,
  StyledInfoTag,
};
