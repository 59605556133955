import { Icons, PillButton } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledCompanyItem,
  StyledCompanyItemDescription,
  StyledCompanyItemTitle,
  StyledCompanyTextContainer,
  StyledEmptyStateContainer,
  StyledEmptyStateDescription,
  StyledEmptyStateIconContainer,
  StyledEmptyStateTitle,
  StyledListContainer,
  StyledRadio,
  StyledSearchInputContainer,
  StyledTextField,
  StyledTreeContainer,
} from "./styles";
import {
  useSelectedCompany,
  useViewMode,
  VIEW_MODE_ENUM,
} from "@flash-tecnologia/hros-web-utility";
import { useMemo, useState } from "react";

type CompaniesListProps = {
  companies: {
    onClick: () => void;
    company: { id: string; name: string; registrationNumber: string };
  }[];
};

export const CompaniesList = ({ companies }: CompaniesListProps) => {
  const { selectedCompany } = useSelectedCompany();
  const { mode } = useViewMode();
  const [search, setSearch] = useState("");

  const isUnifiedVisionActive = mode === VIEW_MODE_ENUM.ECONOMIC_GROUP;

  const filteredCompanies = useMemo(() => {
    return companies
      .filter(({ company }) => {
        return (
          company.name.toLowerCase().includes(search.toLowerCase()) ||
          company.registrationNumber.includes(search)
        );
      })
      .map(({ company, onClick }, index) => {
        const isActive = company.id === selectedCompany.id;
        const activeIndex = companies.findIndex(
          (c) => c.company.id === selectedCompany.id
        );
        return (
          <StyledCompanyItem
            onClick={onClick}
            disabled={isUnifiedVisionActive}
            activePath={isUnifiedVisionActive || activeIndex >= index}
            activeHorizontalPath={isActive || isUnifiedVisionActive}
            active={!isUnifiedVisionActive && isActive}
            key={company.registrationNumber}
          >
            <StyledCompanyTextContainer>
              <StyledCompanyItemTitle
                active={!isUnifiedVisionActive && isActive}
                weight={700}
                variant="body4"
              >
                {company.name}
              </StyledCompanyItemTitle>
              <StyledCompanyItemDescription
                active={!isUnifiedVisionActive && isActive}
                variant="caption"
                weight={400}
              >
                CNPJ {company.registrationNumber}
              </StyledCompanyItemDescription>
            </StyledCompanyTextContainer>
            {isUnifiedVisionActive ? (
              <Icons size={18} name="IconCheck" />
            ) : (
              <StyledRadio onClick={onClick} checked={isActive} size="small" />
            )}
          </StyledCompanyItem>
        );
      });
  }, [selectedCompany, companies, search, isUnifiedVisionActive]);

  const isSearching = useMemo(() => search.length > 0, [search]);
  const hasResults = useMemo(
    () => filteredCompanies.length > 0,
    [filteredCompanies]
  );

  return (
    <>
      <StyledSearchInputContainer active={true}>
        <Icons size={18} name="IconSearch" />
        <StyledTextField
          disabled={isUnifiedVisionActive}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Buscar empresa"
        />
      </StyledSearchInputContainer>
      <StyledListContainer>
        {isSearching && !hasResults ? (
          <EmptyState />
        ) : (
          <StyledTreeContainer>{filteredCompanies}</StyledTreeContainer>
        )}
      </StyledListContainer>
    </>
  );
};

const EmptyState = () => {
  return (
    <StyledEmptyStateContainer>
      <StyledEmptyStateIconContainer>
        <PillButton
          disabled
          icon="IconListSearch"
          size="extra-small"
          variant="default"
        />
      </StyledEmptyStateIconContainer>
      <StyledEmptyStateTitle variant="caption" weight={700}>
        Não encontramos resultados para a sua busca
      </StyledEmptyStateTitle>
      <StyledEmptyStateDescription variant="caption" weight={400}>
        Verifique se o texto digitado corresponde ao nome de alguma empresa.
      </StyledEmptyStateDescription>
    </StyledEmptyStateContainer>
  );
};
