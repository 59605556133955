import {
  IPermissionsFlags,
  getPermissionsFlags,
} from "@flash-tecnologia/hros-web-utility";
import { useEffect, useState } from "react";

type UseUnleashTogglesProps = {
  companyId?: string;
  employeeId?: string;
  economicGroupId?: string;
};

export function useUnleashToggles({
  companyId,
  economicGroupId,
  employeeId,
}: UseUnleashTogglesProps) {
  const [flags, setFlags] = useState<IPermissionsFlags["flags"]>([]);
  const getUnleashToggle = async () => {
    const { flags } = await getPermissionsFlags(
      companyId,
      employeeId,
      economicGroupId
    );
    setFlags(flags || []);
  };

  useEffect(() => {
    getUnleashToggle();
  }, [companyId]);
  return flags;
}
