import { Icons, Tag, Toggle } from "@flash-tecnologia/hros-web-ui-v2";
import * as S from "./styles";
import {
  useViewMode,
  VIEW_MODE_ENUM,
} from "@flash-tecnologia/hros-web-utility";
import { useMemo } from "react";

export const UnifiedVisionToggle = () => {
  const { mode, toggle } = useViewMode();

  const isActive = useMemo(
    () => mode === VIEW_MODE_ENUM.ECONOMIC_GROUP,
    [mode]
  );

  return (
    <S.StyledUnifiedVisionContainer active={isActive}>
      <S.StyledUnifiedVisionHeader>
        <S.StyledUnifiedVisionTitle variant="headline10" weight={700}>
          Grupo econômico
        </S.StyledUnifiedVisionTitle>
        <S.StyledUnifiedVisionDescription variant="caption" weight={600}>
          Ative a Visão unificada para gerenciar todas as empresas do grupo
          econômico ao mesmo tempo.
        </S.StyledUnifiedVisionDescription>
      </S.StyledUnifiedVisionHeader>
      <S.StyledUnifiedVisionFooter active={isActive}>
        <S.StyledUnifiedVisionFooterTitleGroup>
          <S.StyledTagContainer>
            <Tag as="span" size="extra-small" variant="pink">
              <Icons size={14} name="IconWorld" /> Beta
            </Tag>
          </S.StyledTagContainer>
          <S.StyledUnifiedVisionFooterTitle variant="caption">
            Visão unificada
          </S.StyledUnifiedVisionFooterTitle>
        </S.StyledUnifiedVisionFooterTitleGroup>
        <Toggle onChange={() => toggle()} checked={isActive} />
      </S.StyledUnifiedVisionFooter>
    </S.StyledUnifiedVisionContainer>
  );
};
