import { useEffect, useState, useMemo, useContext } from "react";
import {
  getEmployeeNotifications,
  getTotalNewNotifications,
  viewNotifications,
  clearNotifications,
  clearNotificationCount,
} from "@/api";
import { Context } from "@/context";
import { NotificationFeed } from "../../components";
import ProfileDropdown from "./ProfileDropdown";
import SelectCompanyMenu from "./SelectCompanyMenu";
import {
  StyledContainer,
  StyledRightContainer,
  StyledLeftContainer,
} from "./styled";
import { ISelectedCompany } from "./types";
import { setEventTracking, setCompanySelectedAndDispatchEvent } from "@/utils";
import { useSession } from "./hooks/useSession";
import { UserOnboardingStepsMenu } from "./UserOnboardingStepsMenu";
import { useLegacyPermissions } from "@flash-tecnologia/hros-web-utility";
import { useUpdateFeatureFlags } from "@/common/use-update-feature-flags";
import {
  TaskHeaderNotificationIcon,
  HeaderBellNotificationIcon,
} from "@flash-hros/home";

const Header = () => {
  const { selectedCompany, permissions } = useSession();
  const [visible, setVisible] = useState(false);
  const { dispatch, notifications, newNotificationsCount } =
    useContext(Context);

  const { checkSectionPermission, permissions: legacyPermissions } =
    useLegacyPermissions();
  const hasBenefits = useMemo(() => {
    return checkSectionPermission("flash_benefits");
  }, [legacyPermissions]);

  const headerCompanies = useMemo(() => {
    const [employeeCurrentCompany, ...companies] = permissions.companies;
    const companiesWithPermissions = companies.filter(
      (company) => company.permissions.length || permissions.isAdmin
    );

    return [employeeCurrentCompany, ...companiesWithPermissions];
  }, [permissions]);

  useUpdateFeatureFlags();

  useEffect(() => {
    const interval = setInterval(() => {
      (async () => {
        if (document.visibilityState === "hidden") return;
        await getTotalNewNotifications(dispatch);
      })();
    }, 900000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (notifications?.length) {
      clearNotificationCount(dispatch);
      viewNotifications({
        engagementIds: notifications
          ?.filter(({ visualized }) => !visualized)
          ?.map(({ _id }) => _id),
      });
    }
  }, [notifications]);

  useEffect(() => {
    clearNotifications(dispatch);
  }, []);

  const onScroll = async () => {
    const { _id: notificationId } = notifications?.slice(
      notifications?.length - 1
    )[0];

    await getEmployeeNotifications({
      cursor: notificationId,
      limit: 10,
      dispatch,
      notifications,
      paginationMode: true,
    });
  };

  const handleCompanySelection = async (company: ISelectedCompany) => {
    setCompanySelectedAndDispatchEvent(company);
  };

  return (
    <StyledContainer>
      <StyledLeftContainer>
        <SelectCompanyMenu
          companies={headerCompanies}
          selectedCompany={selectedCompany}
          onChange={handleCompanySelection}
        />
        {permissions.isAdmin && hasBenefits && <UserOnboardingStepsMenu />}
      </StyledLeftContainer>
      <StyledRightContainer>
        <TaskHeaderNotificationIcon />
        <HeaderBellNotificationIcon
          onClick={async () => {
            setEventTracking("home_feednotification_clicked");
            setEventTracking(
              "employee_engagement_feednotification_companyfeed_selected"
            );
            setVisible(!visible);
            if (!notifications?.length) {
              await getEmployeeNotifications({
                cursor: null,
                limit: 10,
                dispatch,
              });
            } else if (newNotificationsCount && notifications?.length) {
              getEmployeeNotifications({
                cursor: null,
                limit: newNotificationsCount,
                dispatch,
                notifications,
                paginationMode: false,
              });
            }
          }}
        />
        <ProfileDropdown />
      </StyledRightContainer>
      <NotificationFeed
        visible={visible}
        setVisible={setVisible}
        notifications={notifications}
        onScroll={async () => await onScroll()}
      />
    </StyledContainer>
  );
};

export default Header;
